@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700|Ubuntu:300,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Colors also set in Colors.js */
:root {
  --color-primary: #3E3479;
  --color-primarybackground: #F1F0F6;
  --color-offwhite: #FCFCFF;
  --color-darkgrey: #717171;
  --color-black: #000000;
}

.title {
  font-family: "Ubuntu";
  font-weight: 300;
  font-size: 64px;
  color: #3E3479;
  color: var(--color-primary);
  margin-bottom: 7%;
}

h1 {
  font-family: "Ubuntu";
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  color: var(--color-black);
}

h2 {
  font-family: "Ubuntu";
  font-weight: 400;
  font-size: 36px;
  color: #3E3479;
  color: var(--color-primary);
}

p {
  font-family: "Source Sans Pro";
  font-size: 24;
  color: #000000;
  color: var(--color-black);
}
/* 
 * Must be loaded before Components.
 */
